<script lang="ts">
  import clsx from "clsx";
  import type { IconProps } from "lucide-svelte";
  import type { SvelteComponent } from "svelte";

  import AccordionListItem from "../AccordionListItem.svelte";
  import MarketingButton from "../MarketingButton.svelte";

  type Detail = {
    icon: typeof SvelteComponent<IconProps>;
    headline: string;
    sublineHtml: string;
  };

  export let titleGreen: string;
  export let titleContinued: string;
  export let titleReversed: boolean = false;
  export let link: string | undefined = undefined;
  export let details: Detail[];
  export let placement: "left" | "right";
</script>

<div class="my-12 md:mt-24 md:mb-36 grid md:grid-cols-12 gap-x-5 gap-y-12">
  <div class="md:col-span-5" class:md:order-2={placement === "right"}>
    <h3 class="marketing-h3 md:mb-8">
      {#if titleReversed}
        {titleContinued}
        <span class="text-brand-green">{titleGreen}</span>
      {:else}
        <span class="text-brand-green">{titleGreen}</span>
        {titleContinued}
      {/if}
    </h3>
    {#if link}
      <div class="hidden md:block">
        <MarketingButton href={link}>View Examples</MarketingButton>
      </div>
    {/if}

    <div class="hidden md:block mt-12">
      {#each details as { icon, headline, sublineHtml }}
        <hr
          class={clsx(
            "border-light-green/30",
            placement === "left"
              ? "marketing-contained-negate-ml"
              : "marketing-contained-negate-mr",
          )}
        />
        <div class="py-6 flex gap-5">
          <svelte:component this={icon} class="text-brand-green shrink-0" />
          <div>
            <p class="marketing-h4 mb-4">{headline}</p>
            <p class="text-light-green/60 max-w-sm">
              {@html sublineHtml}
            </p>
          </div>
        </div>
      {/each}
    </div>
  </div>

  <div
    class="hidden md:block col-span-1"
    class:order-1={placement === "right"}
  />

  <div class="md:col-span-6" class:md:order-0={placement === "right"}>
    <div
      class={clsx(
        "h-full",
        placement === "left"
          ? "md:marketing-contained-negate-mr"
          : "md:marketing-contained-negate-ml",
      )}
    >
      <slot />
    </div>
  </div>

  <div class="md:hidden">
    <div class="mb-6 md:my-12">
      {#each details as { icon, headline, sublineHtml }}
        <AccordionListItem {icon} {headline}>
          <p>{@html sublineHtml}</p>
        </AccordionListItem>
      {/each}
    </div>
    {#if link}
      <MarketingButton href={link}>View Examples</MarketingButton>
    {/if}
  </div>
</div>
